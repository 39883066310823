* {
  text-decoration: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: inherit;
  line-height: 1.5;
}
body {
  font-size: 1em;
}
input,
select {
  background: #ededed;
  background-image: none;
  display: block;
  height: 38px;
  font-size: 1em;
  border-radius: 8px;
  border: 2px solid transparent;
  font-family: 'Roboto', sans-serif;
}
input:focus-visible {
  outline: none;
}
input:focus {
  border: 2px solid #366eda;
  border-radius: 8px;
}
fieldset {
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
